// src/gatsby-plugin-theme-ui/index.js

import novelaTheme from '@davidway/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
  ...novelaTheme,
  initialColorMode: `light`,
  colors: {
    ...novelaTheme.colors,
    accent: '#EC6C20'
  }
};